import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import vuetify from "./plugins/vuetify"
import VueResource from "vue-resource"
import VueJsonp from "vue-jsonp"
import helpers from "./components/helper/global"
import VueMatomo from "vue-matomo"
import VueCryptojs from "vue-cryptojs"
import io from "socket.io-client"
import VueTour from "vue-tour"
import VueEllipseProgress from "vue-ellipse-progress"
import axios from "axios"
require("vue-tour/dist/vue-tour.css")
import VueCompositionAPI from "@vue/composition-api"

Vue.use(VueCompositionAPI)
//socket io

const socket = io(process.env.VUE_APP_SOCKET_IO_URL, {
    transports: ["polling", "websocket"]
})
socket.on("connect_error", () => {
    // revert to classic upgrade
    socket.io.opts.transports = ["websocket", "polling"]
})

Vue.config.productionTip = false
Vue.use(VueTour)
Vue.use(VueResource)
Vue.use(VueJsonp)
Vue.use(VueEllipseProgress)

Vue.use(VueMatomo, {
    host: "https://control.searchinsights.de/",
    siteId: process.env.VUE_APP_MATOMO_ID,
    trackerFileName: "matomo",
    router: router,
    enableLinkTracking: true,
    requireConsent: false,
    trackInitialView: true,
    disableCookies: false,
    enableHeartBeatTimer: false,
    heartBeatTimerInterval: 15,
    debug: false,
    userId: undefined,
    cookieDomain: undefined,
    domains: undefined,
    preInitActions: []
})

Vue.use(VueCryptojs)

Vue.use({
    install() {
        Vue.helpers = helpers
        Vue.prototype.$helpers = helpers
        Vue.socket = socket
        Vue.prototype.$socket = socket
    }
})

new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App)
}).$mount("#app")
