import "@fortawesome/fontawesome-free/css/all.css"
import Vue from "vue"
import Vuetify from "vuetify/lib"
import LeafIcon from "@/components/icons/serp_items/LeafIcon.vue"
import MoneyIcon from "@/components/icons/serp_items/MoneyIcon.vue"
import ShoppingIcon from "@/components/icons/serp_items/ShoppingIcon.vue"
import VideoIcon from "@/components/icons/serp_items/VideoIcon.vue"
import ImagesIcon from "@/components/icons/serp_items/ImagesIcon.vue"
import AnswerBoxIcon from "@/components/icons/serp_items/AnswerBoxIcon.vue"
import MagnifyIcon from "@/components/icons/MagnifyIcon.vue"
import DBIcon from "@/components/icons/DBIcon.vue"
import LogoutIcon from "@/components/icons/LogoutIcon.vue"
import BellIcon from "@/components/icons/BellIcon.vue"
import FindResultsOn from "@/components/icons/serp_items/FindResultsOn.vue"
import CarouselIcon from "@/components/icons/serp_items/CarouselIcon.vue"
import LocalPack from "@/components/icons/serp_items/LocalPack.vue"
import PeopleAlsoAsked from "@/components/icons/serp_items/PeopleAlsoAsked.vue"
import RelatedSearches from "@/components/icons/serp_items/RelatedSearches.vue"
import RichSnippet from "@/components/icons/serp_items/RichSnippet.vue"
import MultiCarouselIcon from "@/components/icons/serp_items/MultiCarouselIcon.vue"
// import AppIcon from "@/components/icons/serp_items/AppIcon.vue"
import PopularProducts from "@/components/icons/serp_items/PopularProducts.vue"
import GoogleReviews from "@/components/icons/serp_items/GoogleReviews.vue"
import GooglePosts from "@/components/icons/serp_items/GooglePosts.vue"
import GoogleFlights from "@/components/icons/serp_items/GoogleFlights.vue"
import FeaturedSnippet from "@/components/icons/serp_items/FeaturedSnippet.vue"
import LocalServices from "@/components/icons/serp_items/LocalServices.vue"
import Recipes from "@/components/icons/serp_items/Recipes.vue"

import CommercialUnits from "@/components/icons/serp_items/CommercialUnits.vue"
import StocksBox from "@/components/icons/serp_items/StocksBox.vue"
import QuestionsAndAnswers from "@/components/icons/serp_items/QuestionsAndAnswers.vue"
import Podcasts from "@/components/icons/serp_items/Podcasts.vue"
import ScholarlyArticles from "@/components/icons/serp_items/ScholarlyArticles.vue"
import TopSights from "@/components/icons/serp_items/TopSights.vue"
import VisualStories from "@/components/icons/serp_items/VisualStories.vue"
import MapIcon from "@/components/icons/serp_items/MapIcon.vue"
import HotelsPack from "@/components/icons/serp_items/HotelsPack.vue"
import MentionCarousel from "@/components/icons/serp_items/MentionCarousel.vue"
import Events from "@/components/icons/serp_items/Events.vue"
import TopStories from "@/components/icons/serp_items/TopStories.vue"
import PeopleAlsoSearch from "@/components/icons/serp_items/PeopleAlsoSearch.vue"
import KnowledgeGraph from "@/components/icons/serp_items/KnowledgeGraph.vue"
import Jobs from "@/components/icons/serp_items/Jobs.vue"
import Twitter from "@/components/icons/serp_items/Twitter.vue"
import VueCryptojs from "vue-cryptojs"
import { customThemes } from "./themes.js"

Vue.use(VueCryptojs)
Vue.use(Vuetify)
const company_id = localStorage.company_id ? Vue.CryptoJS.AES.decrypt(localStorage.company_id, process.env.VUE_APP_ENCRYPTION_PW).toString(Vue.CryptoJS.enc.Utf8) : null

let theme = customThemes.find((e) => e.id == company_id)
const lightTheme = theme ? theme.light : customThemes[0].light
const darkTheme = theme ? theme.dark : customThemes[0].dark
console.log(lightTheme)
export default new Vuetify({
    theme: {
        // light: true,
        dark: false,
        options: {
            customProperties: true
        },
        themes: {
            light: lightTheme,
            dark: darkTheme
        }
    },
    icons: {
        iconfont: "fa",
        values: {
            people_also_search: {
                component: PeopleAlsoSearch // our custom component
            },
            knowledge_graph: {
                component: KnowledgeGraph // our custom component
            },
            jobs: {
                component: Jobs // our custom component
            },
            twitter: {
                component: Twitter // our custom component
            },
            commercial_units: {
                component: CommercialUnits // our custom component
            },
            stocks_box: {
                component: StocksBox // our custom component
            },
            questions_and_answers: {
                component: QuestionsAndAnswers // our custom component
            },
            podcasts: {
                component: Podcasts // our custom component
            },
            scholarly_articles: {
                component: ScholarlyArticles // our custom component
            },
            top_sights: {
                component: TopSights // our custom component
            },
            visual_stories: {
                component: VisualStories // our custom component
            },
            map: {
                component: MapIcon // our custom component
            },
            hotels_pack: {
                component: HotelsPack // our custom component
            },
            mention_carousel: {
                component: MentionCarousel // our custom component
            },
            events: {
                component: Events // our custom component
            },
            top_stories: {
                component: TopStories // our custom component
            },

            recipes: {
                component: Recipes // our custom component
            },
            local_services: {
                component: LocalServices // our custom component
            },
            featured_snippet: {
                component: FeaturedSnippet // our custom component
            },
            google_flights: {
                component: GoogleFlights // our custom component
            },
            google_posts: {
                component: GooglePosts // our custom component
            },
            google_reviews: {
                component: GoogleReviews // our custom component
            },
            popular_products: {
                component: PopularProducts // our custom component
            },
            // app: {
            //     component: AppIcon // our custom component
            // },
            multi_carousel: {
                component: MultiCarouselIcon // our custom component
            },
            find_results_on: {
                component: FindResultsOn // our custom component
            },
            carousel: {
                component: CarouselIcon // our custom component
            },
            local_pack: {
                component: LocalPack // our custom component
            },
            people_also_asked: {
                component: PeopleAlsoAsked // our custom component
            },
            related_searches: {
                component: RelatedSearches // our custom component
            },
            rich_snippet: {
                component: RichSnippet // our custom component
            },
            organic: {
                component: LeafIcon // our custom component
            },
            paid: {
                component: MoneyIcon // our custom component
            },
            images: {
                component: ImagesIcon // our custom component
            },
            shopping: {
                component: ShoppingIcon // our custom component
            },
            video: {
                component: VideoIcon // our custom component
            },
            answer_box: {
                component: AnswerBoxIcon // our custom component
            },
            magnify: {
                component: MagnifyIcon // our custom component
            },
            database: {
                component: DBIcon // our custom component
            },
            logout: {
                component: LogoutIcon // our custom component
            },
            bell: {
                component: BellIcon // our custom component
            }
        }
    }
})
// xxxlutz: {
//     shadow1: "#dbdbdb",
//     shadow2: "#ffffff",
//     primary: "#004279",
//     lightgreen: "#1cedb2",
//     darkgreen: "#009f89",
//     errorred: "#ed2f59",
//     secondary: "white",
//     background: "#ececec",
//     keywordcolhover: "#e7e7e7",
//     keywordcol: "#f3f3f3", //keyword table first col
//     gradient1: "rgba(0, 159, 137, 0.8)",
//     gradient2: "rgba(0, 159, 137, 0.3)",
//     gradient3: "rgba(0, 159, 137, 0)",
//     sparklineGradient1: "rgb(0, 159, 137)",
//     sparklineGradient2: "rgb(0, 194, 167)",
//     sparklineGradient3: "rgb(38, 222, 196)",
//     background: "#f5fefb",
//     background_gradient_1: "#f5fefb",
//     background_gradient_2: "#f7fcfb",
//     background_gradient_3: "#fafafa",
//     background_gradient_4: "#f9f5f7",
//     background_gradient_5: "#faf4f7",
//     topnavbackground: "#f6f8fbB3",
//     navcolor: "#B2f6f8fb",
//     q_grey: "#ebeaea",
//     q_grey_darken_1: "#d4d4d4"
// },
