const request = require("request")
/* globals localStorage */

export default {
    login(email, pass, cb) {
        cb = arguments[arguments.length - 1]
        // if (localStorage.token) {
        //   if (cb) cb(true)
        //   this.onChange(true)
        //   return
        // }
        checkUser(email, pass, (res) => {
            if (res.userfound) {
                if (res.active) {
                    if (res.authenticated) {
                        // //console.log(res.id)
                        if (res.verification) {
                            localStorage.token = res.token
                            localStorage.user = res.mail
                            localStorage.id = res.id
                            localStorage.company_id = res.company_id
                            localStorage.plan = res.plan
                            localStorage.username = res.username
                            if (cb) cb({ authentication: true, verification: true, userfound: true, active: true })
                            this.onChange(true)
                        } else {
                            if (cb) cb({ authentication: true, verification: false, userfound: true, active: true })
                            this.onChange(true)
                        }
                    } else {
                        if (res.verification) {
                            if (cb) cb({ authentication: false, verification: true, userfound: true, active: true })
                            this.onChange(true)
                        } else {
                            if (cb) cb({ authentication: false, verification: false, userfound: true, active: true })
                            this.onChange(true)
                        }
                    }
                } else {
                    if (cb) cb({ userfound: true, active: false })
                    this.onChange(true)
                }
            } else {
                if (cb) cb({ userfound: false })
                this.onChange(true)
            }
        })
    },

    getToken() {
        return localStorage.token
    },

    logout(cb) {
        delete localStorage.token
        delete localStorage.user
        delete localStorage.id
        delete localStorage.company_id
        delete localStorage.plan
        delete localStorage.username
        if (cb) cb()
        this.onChange(false)
    },

    ssoAuth(sso_token) {
        // //console.log(localStorage.token)
        var obj = {
            method: "POST",
            headers: {
                Authorization: "Basic " + window.btoa(process.env.VUE_APP_AUTH),
                Accept: "application/json",
                "Content-Type": "application/json",
                Origin: "localhost",
                Host: "localhost"
            },
            body: JSON.stringify({
                sso_token: sso_token
            })
        }

        return fetch(process.env.VUE_APP_APIURL + "/user/login/sso", obj)
            .then((response) => response.json())
            .then((responseData) => {
                // console.log(responseData)

                if (responseData) {
                    localStorage.token = responseData.token
                    localStorage.user = responseData.user
                    localStorage.id = responseData.id
                    localStorage.company_id = responseData.company_id
                    localStorage.plan = responseData.plan
                    localStorage.username = responseData.username
                    console.log("SSO Token found, logging in")
                    return responseData
                } else {
                    console.log("Invalid SSO Token")
                    delete localStorage.token
                    delete localStorage.user
                    delete localStorage.id
                    delete localStorage.company_id
                    delete localStorage.plan
                    delete localStorage.username
                    return false
                }
            })
            .catch((error) => console.log(error))
    },

    loggedIn() {
        // //console.log(localStorage.token)
        var obj = {
            method: "POST",
            headers: {
                Authorization: "Basic " + window.btoa(process.env.VUE_APP_AUTH),
                Accept: "application/json",
                "Content-Type": "application/json",
                Origin: "localhost",
                Host: "localhost"
            },
            body: JSON.stringify({
                user: localStorage.user,
                token: localStorage.token
            })
        }

        return fetch(process.env.VUE_APP_APIURL + "/user/tokencheck", obj)
            .then((response) => response.json())
            .then((responseData) => {
                // //console.log(responseData.token, localStorage.token)
                console.log(responseData)
                if (responseData.loggedIn) {
                    console.log("Valid Token")
                    return true
                } else {
                    console.log("Invalid Token")
                    return false
                }
            })
            .catch((error) => {
                console.log("LOGIN ERROR")
                console.log(error)
                return { no_redirect: true }
                // if (error == "TypeError: NetworkError when attempting to fetch resource.") return true
            })
    },
    apiOnline() {
        // //console.log(localStorage.token)
        var obj = {
            method: "GET",
            headers: {
                Authorization: "Basic " + window.btoa(process.env.VUE_APP_AUTH),
                Accept: "application/json",
                "Content-Type": "application/json",
                Origin: "localhost",
                Host: "localhost"
            }
            // body: JSON.stringify({
            //     user: localStorage.user,
            //     token: localStorage.token
            // })
        }

        return fetch("http://localhost:3010/status" + "/quaro/api", obj)
            .then((response) => response.json())
            .then((responseData) => {
                console.log("RESPONSE:", responseData)

                if (responseData.api_online) {
                    return true
                } else {
                    return false
                }
            })
            .catch((error) => console.log("RESPONSE ERROR:", error))
    },
    onChange() {}
}

function checkUser(email, pass, cb) {
    var obj = {
        method: "POST",
        headers: {
            Authorization: "Basic " + window.btoa(process.env.VUE_APP_AUTH),
            Accept: "application/json",
            "Content-Type": "application/json",
            Origin: "localhost",
            Host: "localhost"
        },
        body: JSON.stringify({
            email: email,
            pass: pass
        })
    }

    fetch(process.env.VUE_APP_APIURL + "/user/login", obj)
        .then(function (response) {
            return response.json()
        })
        .then(function (data) {
            // console.log(data);
            if (!data.nousererror) {
                if (!data.inactiverror) {
                    if (!data.verificationerror) {
                        if (data.login == true) {
                            cb({
                                userfound: true,
                                active: true,
                                authenticated: true,
                                token: data.token,
                                id: data.id,
                                company_id: data.company_id,
                                verification: true,
                                plan: data.plan,
                                username: data.username,
                                mail: data.mail
                            })
                        } else {
                            cb({ authenticated: false, verification: true, userfound: true, active: true })
                        }
                    } else {
                        //console.log("verification error")
                        cb({ authenticated: true, verification: false, userfound: true, active: true })
                    }
                } else {
                    cb({ userfound: true, active: false })
                }
            } else {
                cb({ userfound: false })
            }
        })
        .catch(function () {
            //console.log("Fehler beim Usercheck");
        })
}
