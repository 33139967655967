/**
 * Season and peak for keyword
 */

export default {
    // /**
    //  * adds season and peak to each keyword
    //  * @param {*} svArray
    //  * @param {*} sv
    //  * @returns
    //  */
    // addPeakAndSeason: function (keywords) {
    //     keywords.map((keyword) => {
    //         let search_volumes_copy = JSON.parse(JSON.stringify(keyword.search_volumes)) // create save copy
    //         let svArray = search_volumes_copy.slice(0, 12) // get latest 12 entries for season
    //         if (!this.allValuesSame(svArray)) {
    //             // check if searchvolumes is the same in every month
    //             keyword.peak = this.addPeakMonth(svArray)
    //             keyword.season = this.addSeason(svArray)
    //         }
    //     })
    //     console.log(keywords)
    // },

    /**
     * calculates season
     * @param {*} svArray
     * @returns
     */
    addSeason(svArray) {
        let onlySV = svArray.map((entry) => entry.count)
        // calculate mean
        const sum = onlySV.reduce(function (pv, cv) {
            return pv + cv
        }, 0)
        const mean = sum / svArray.length
        // calculate standardabweichung und min max abweichung
        let data = {
            minMaxAbw: (((Math.max.apply(null, onlySV) - Math.min.apply(null, onlySV)) / Math.max.apply(null, onlySV)) * 100).toFixed(2),
            stabw: this.getStabw(onlySV, mean),
            season: []
        }

        // adjust here
        if (data.minMaxAbw > 50 || data.stabw > 25) {
            for (let index = svArray.length - 1; index >= 0; index--) {
                let abw = (-1 * (mean - parseInt(svArray[index].count))) / mean
                if (abw > 0) {
                    data.season.push(svArray[index].month)
                }
            }
            data.seasonData = this.getSeason(data)
            // console.log(data.seasonData)
            data.season = this.getSeasonArr(data)
            // console.log(data.season)
            return data
        } else {
            return null
        }
    },

    /**
     *
     * @param {*} search_volumes
     */
    addPeakMonth(search_volumes) {
        let monthArray = ["", "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
        var peak = search_volumes.sort((a, b) => b.count - a.count)[0]
        return monthArray[peak.month]
    },
    getlatest12(search_volumes) {
        let first12 = []
        if (search_volumes.length >= 12) {
            for (let index = 0; index < 12; index++) {
                first12.push(search_volumes[index])
            }
            return first12
        }
        return []
    },
    allValuesSame(search_volumes) {
        for (var i = 1; i < search_volumes.length; i++) {
            if (search_volumes[i] !== search_volumes[0]) return false
        }
        return true
    },
    getStabw: function (array) {
        var len = 0
        var sum = array.reduce(function (pv, cv) {
            ++len
            return pv + cv
        }, 0)
        var mean = sum / len
        var result = 0
        for (var i = 0; i < len; i++) result += Math.pow(array[i] - mean, 2)
        len = len == 1 ? len : len - 1
        let stabw = Math.sqrt(result / len)
        return ((stabw / mean) * 100).toFixed(2)
    },
    getSeasonArr: function (data) {
        let arr = []
        for (let index = 0; index < data.seasonData.length; index++) {
            if (data.seasonData[index] != "-") {
                let temparr = []
                for (; index < data.seasonData.length; index++) {
                    if (data.seasonData[index] === "-") {
                        arr.push(temparr)
                        break
                    } else {
                        if (index === 11) {
                            temparr.push(data.seasonData[index])
                            if (arr.length > 0) {
                                for (let j = temparr.length - 1; j >= 0; j--) {
                                    arr[0].unshift(temparr[j])
                                }
                            } else {
                                arr.push(temparr)
                            }
                        } else {
                            temparr.push(data.seasonData[index])
                        }
                    }
                }
            }
        }
        let res = ""
        let helparr = []
        for (let index = 0; index < arr.length; index++) {
            if (arr[index].length > helparr.length) {
                helparr = arr[index]
            }
        }
        if (helparr.length > 1) {
            return helparr[0] + " - " + helparr[helparr.length - 1]
        } else {
            return helparr[0]
        }
    },
    getSeason: function (data) {
        let monthArray = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
        // let seasonData = ["-", "-", "-", "-", "-", "-", "-", "-", "-", "-", "-", "-"]

        // converts month in number to text and adds at the right order to seasonData
        let seasonData = monthArray.map((month, index) => {
            let entryExists = data.season.find((monthNum) => monthNum === index)
            if (entryExists) {
                return month
            } else {
                return "-"
            }
        })

        // console.log("data.season", data.season)
        // for (let j = 0; j < monthArray.length; j++) {
        //     for (let index = 0; index < data.season.length; index++) {
        //         if (data.season[index] === monthArray[j]) {
        //             seasonData[j] = data.season[index]
        //         }
        //     }
        // }
        return seasonData
    }
}
