<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 103 85.63">
        <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
                <path
                    class="cls-1"
                    d="M95.69,12.09,95,6.61A7.57,7.57,0,0,0,86.37.08L7,12.12a6.93,6.93,0,0,0-1.29.2,3,3,0,0,0-.73.23,7.56,7.56,0,0,0-5,7.09V78.05a7.59,7.59,0,0,0,7.57,7.58H95.43A7.59,7.59,0,0,0,103,78.05V19.64A7.57,7.57,0,0,0,95.69,12.09Zm-8.43-6a1.52,1.52,0,0,1,1.13.3,1.48,1.48,0,0,1,.58,1l.61,4.68H47.77Zm9.68,72a1.52,1.52,0,0,1-1.51,1.52H7.57a1.52,1.52,0,0,1-1.51-1.52V19.64A1.5,1.5,0,0,1,7,18.26l.88-.14H95.43a1.52,1.52,0,0,1,1.51,1.52Z" />
                <path
                    class="cls-1"
                    d="M51.24,36.85a1.14,1.14,0,0,0,.52,0A4.47,4.47,0,0,1,56,41.31a3,3,0,0,0,6.06,0,10.57,10.57,0,0,0-7.51-10.1v-1.6a3,3,0,0,0-6.06,0v1.6A10.57,10.57,0,0,0,41,41.31c0,4.81,3.23,8.31,9.6,10.39,5,1.64,5.42,3.12,5.42,4.63a4.48,4.48,0,1,1-9,0,3,3,0,0,0-6.06,0,10.57,10.57,0,0,0,7.51,10.1v1.65a3,3,0,0,0,6.06,0V66.43A10.57,10.57,0,0,0,62,56.33c0-7.26-6.73-9.45-9.6-10.39C47,44.17,47,42.16,47,41.31A4.47,4.47,0,0,1,51.24,36.85Z" />
                <path class="cls-1" d="M86.58,45.82H79.14a3,3,0,0,0,0,6.06h7.44a3,3,0,0,0,0-6.06Z" />
                <path class="cls-1" d="M23.86,45.82H16.42a3,3,0,0,0,0,6.06h7.44a3,3,0,1,0,0-6.06Z" />
            </g>
        </g>
    </svg>
</template>

<script>
    export default {}
</script>

<style></style>
