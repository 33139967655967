<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 68 27.85">
        <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
                <path d="M51.19,4H16.81a2,2,0,1,1,0-4H51.19a2,2,0,0,1,0,4Z" />
                <path d="M51.19,15.93H16.81a2,2,0,1,1,0-4H51.19a2,2,0,0,1,0,4Z" />
                <path d="M51.19,27.85H16.81a2,2,0,1,1,0-4H51.19a2,2,0,0,1,0,4Z" />
                <path d="M59,23a2,2,0,0,1-1.42-3.4l5.62-5.7L57.41,8.28A2,2,0,0,1,60.2,5.42l7.2,7a2,2,0,0,1,.6,1.41,2,2,0,0,1-.58,1.42l-7,7.13A2,2,0,0,1,59,23Z" />
                <path d="M9,23a2,2,0,0,1-1.43-.6l-7-7.13A2,2,0,0,1,0,13.85a2,2,0,0,1,.6-1.41l7.2-7a2,2,0,0,1,2.79,2.86L4.84,13.9l5.62,5.7A2,2,0,0,1,9,23Z" />
            </g>
        </g>
    </svg>
</template>

<script>
    export default {}
</script>

<style></style>
