<template>
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 400">
        <title>_</title>
        <path
            d="M200,143.82c-57.81,0-116.33-12.79-116.33-37.25S142.19,69.32,200,69.32s116.33,12.79,116.33,37.25S257.81,143.82,200,143.82Zm0-62.49c-68.5,0-104.33,16.44-104.33,25.25S131.5,131.82,200,131.82s104.33-16.44,104.33-25.25S268.5,81.32,200,81.32Z" />
        <path
            d="M200,206.31c-57.81,0-116.33-12.8-116.33-37.25V106.57a6,6,0,0,1,12,0v62.49c0,8.81,35.83,25.25,104.33,25.25s104.33-16.44,104.33-25.25V106.57a6,6,0,1,1,12,0v62.49C316.33,193.52,257.81,206.31,200,206.31Z" />
        <path
            d="M200,206.31c-12.27,0-24.36-.56-36-1.67q-2.61-.25-5.16-.53l-2.83-.33-4.35-.56c-3.39-.46-6.76-1-10.32-1.59-3.06-.54-5.73-1-8.2-1.57-1.66-.34-3.43-.74-5.24-1.17-5.36-1.26-10.42-2.7-15-4.28-16.18-5.55-25.75-12.53-28.45-20.73a12.93,12.93,0,0,1-.51-1.94,14.93,14.93,0,0,1-.29-2.88,6,6,0,0,1,12,0h0a2.92,2.92,0,0,0,.07.58l0,.21c0,.05,0,.13.06.2.54,1.64,3.58,7.23,21,13.2,4.24,1.45,8.91,2.78,13.9,4,1.71.4,3.37.77,4.95,1.1,2.35.5,4.89,1,7.78,1.49,3.4.58,6.63,1.08,9.86,1.52l4.19.54,2.77.33c1.6.18,3.27.35,4.94.51,11.21,1.07,22.92,1.61,34.81,1.61s23.6-.54,34.81-1.61c1.67-.16,3.33-.33,5-.51l2.74-.32,4.2-.54c3.21-.43,6.44-.93,9.87-1.52,2.87-.5,5.4-1,7.73-1.48,1.61-.33,3.27-.7,5-1.11,5-1.17,9.67-2.5,13.91-4,17.39-6,20.43-11.56,20.94-13.13a2.86,2.86,0,0,0,.09-.28l0-.19a2.92,2.92,0,0,0,.07-.58,6,6,0,0,1,12,0,15,15,0,0,1-.29,2.89,13.32,13.32,0,0,1-.53,2c-2.68,8.14-12.25,15.11-28.43,20.66-4.62,1.58-9.68,3-15.05,4.28-1.79.42-3.56.82-5.25,1.17-2.44.52-5.11,1-8.15,1.56-3.59.61-7,1.13-10.33,1.59l-4.35.56-2.81.33c-1.74.19-3.46.37-5.2.54C224.36,205.75,212.27,206.31,200,206.31Z" />
        <path
            d="M200,268.81c-57.81,0-116.33-12.8-116.33-37.25V169.07a6,6,0,0,1,12,0v62.49c0,8.81,35.83,25.25,104.33,25.25s104.33-16.44,104.33-25.25V169.07a6,6,0,0,1,12,0v62.49C316.33,256,257.81,268.81,200,268.81Z" />
        <path d="M200,268.18c-57.81,0-116.33-12.79-116.33-37.24a6,6,0,0,1,12,0c0,8.81,35.83,25.24,104.33,25.24s104.33-16.43,104.33-25.24a6,6,0,0,1,12,0C316.33,255.38,257.81,268.18,200,268.18Z" />
        <path d="M215.49,206h-.24c-5.14-.2-10.27-.3-15.26-.3s-10.12.1-15.26.3a6,6,0,1,1-.39-12c5.26-.2,10.53-.31,15.64-.31s10.43.1,15.72.31a6,6,0,0,1-.23,12Z" />
        <path
            d="M200,330.68c-57.81,0-116.33-12.8-116.33-37.25V230.94a6,6,0,0,1,12,0v62.49c0,8.81,35.83,25.25,104.33,25.25s104.33-16.44,104.33-25.25V230.94a6,6,0,0,1,12,0v62.49C316.33,317.88,257.81,330.68,200,330.68Z" />
    </svg>
</template>

<script>
    export default {}
</script>

<style></style>
