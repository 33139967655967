import datasources from "./datasources.js"
import { all_serp_items } from "./serp_items.js"
export default {
    /**
     * Fetches DFS histSV
     * @param {*} keywords
     * @param {*} selectedLocation
     * @param {*} localStorage
     */
    fetchDFSHistSV(keywords, selectedLocation, localStorage) {
        return new Promise(async (resolve, reject) => {
            try {
                console.log(localStorage)
                // let keywordPackage = keywords.slice(pagination.pageStart, pagination.pageStop)
                let keywordArray = splitArray(keywords, 700)
                // fetching data...
                let alldata = []
                for (let split = 0; split < keywordArray.length; split++) {
                    let historicalData = await datasources.fetchHistoricalSV(keywordArray[split], selectedLocation.ads_lang_code, selectedLocation.value, localStorage.company_id, localStorage.id) //includes categories
                    alldata = [...alldata, ...historicalData.entries]
                }

                resolve(alldata)
            } catch (error) {
                console.log(error)
                reject(error)
            }
        })
    },
    formatGoogleResponse(response_data, allCategories) {
        return new Promise((resolve, reject) => {
            try {
                let formattedData = response_data.map((entry) => {
                    let obj = {}
                    obj.keyword = entry.data[0].value.value
                    obj.serp_items = this.getSERPItemsObject(entry.data[4] ? (entry.data[4].value ? entry.data[4].value.serp_item_types : null) : null)
                    obj.keyword_difficulty = this.getDifficultyObject(entry.data[5] ? (entry.data[5].value ? entry.data[5].value.keyword_difficulty : null) : null)
                    obj.categories = entry.data[1].value.categories

                    let firstentry = entry.data[1].value
                    let annual = 0
                    for (let sumindex = 0; sumindex < 12; sumindex++) {
                        annual += parseInt(firstentry.value[sumindex].count) || 0
                    }
                    let row = {
                        annualsv: annual,
                        avgsv: parseInt(annual / 12),
                        search_volumes: [],
                        dialog: false,
                        adwords_cpc_high: entry.data[2].value.value.high,
                        adwords_cpc: entry.data[2].value.value.high,
                        adwords_cpc_low: entry.data[2].value.value.low,
                        adwords_competition: entry.data[3].value && entry.data[3].value.value !== null ? entry.data[3].value.value.toFixed(0) : null,

                        active_cat_index: 0
                    }

                    for (let monthCounter = 0; monthCounter < firstentry.value.length; monthCounter++) {
                        let volume = {
                            count: parseInt(firstentry.value[monthCounter].count) || 0,
                            year: firstentry.value[monthCounter].year,
                            month: firstentry.value[monthCounter].month
                        }
                        row.search_volumes.push(volume)

                        let svname = ""
                        if (firstentry.value[monthCounter].month < 10) {
                            svname = firstentry.value[monthCounter].year.toString() + "0" + firstentry.value[monthCounter].month.toString()
                        } else {
                            svname = firstentry.value[monthCounter].year.toString() + firstentry.value[monthCounter].month.toString()
                        }
                        row[svname] = parseInt(firstentry.value[monthCounter].count) || 0
                    }

                    obj = { ...obj, ...row }
                    return obj
                })
                formattedData = this.addCats(formattedData, allCategories)

                resolve(formattedData)
            } catch (error) {
                reject(error)
            }
        })
    },
    // CATEGORIES SECTION
    /**
     * Fetches Categories and sets data for keywords table
     * @param {*} keywords
     * @param {*} selectedLocation
     * @param {*} localStorage
     * @param {*} allCatData
     * @param {*} catsLoading
     * @param {*} keywords_ideas
     */
    fetchCategories(keywords, selectedLocation, localStorage, allCatData, catsLoading, keywords_ideas) {
        return new Promise(async (resolve, reject) => {
            try {
                console.log(localStorage)
                // let keywordPackage = keywords.slice(pagination.pageStart, pagination.pageStop)
                let keywordArray = splitArray(keywords, 700)
                // fetching data...
                for (let split = 0; split < keywordArray.length; split++) {
                    let historicalData = await datasources.fetchHistoricalSV(keywordArray[split], selectedLocation.ads_lang_code, selectedLocation.value, localStorage.company_id, localStorage.id) //includes categories
                    let data = await this.extractAdditionalData(historicalData, allCatData)
                    data.map((entry) => {
                        let indexOfKeyword = keywords.findIndex((keywordRow) => keywordRow.keyword == entry.keyword)
                        keywords[indexOfKeyword].cats = this.sortCats(entry.categories)
                        keywords[indexOfKeyword].keyword_difficulty = entry.keyword_difficulty
                        keywords[indexOfKeyword].serp_items = entry.serp_items
                    })
                    // setting cats of seed kw for other keywords if seed kw is included
                    if (keywords_ideas) {
                        let seedKeyword = keywords_ideas.split(",")[0]
                        let indexOfSeedKeyword = keywords.findIndex((keywordRow) => keywordRow.keyword == seedKeyword)
                        if (keywords[indexOfSeedKeyword] && keywords[indexOfSeedKeyword].cats) {
                            let catOfSeedKeyword = JSON.parse(JSON.stringify(keywords[indexOfSeedKeyword].cats[0]))
                            keywords.map((keywordRow) => {
                                if (!keywordRow.cats && keywordRow.keyword.includes(seedKeyword)) {
                                    keywordRow.cats = [JSON.parse(JSON.stringify(catOfSeedKeyword))]
                                }
                            })
                        }
                    }
                    if (split == 0) {
                        keywords.push({})
                        keywords.pop()
                    }
                }

                resolve()
            } catch (error) {
                console.log(error)
                reject(error)
            }
        })
    },
    addCats(keywords, allCategories) {
        let newkw = keywords.map((keyword) => {
            let row = {}
            row.keyword = keyword.keyword
            row.categories = this.translateCats(keyword.categories, allCategories)
            return row
        })
        let catData = newkw
        let onlykeywords = keywords.map((x) => x.keyword)
        // console.log(onlykeywords)
        // console.log(allCategories)
        for (let index = 0; index < catData.length; index++) {
            let pos = onlykeywords.indexOf(catData[index].keyword)
            keywords[pos].cats = this.sortCats(catData[index].categories)
            if (keywords[pos].cats) {
                keywords[pos].cats.forEach((cat) => {
                    cat.active = false
                })
                keywords[pos].cats[0].active = true
            }
        }
        return keywords
    },
    /**
     * Builds Tree from catgories based on parent codes
     * @param {*} allCats
     * @returns
     */
    sortCats(allCats) {
        if (!allCats) return null
        let sortedCats = []
        let catRoots = []
        let childCats = allCats.filter((x) => x.category_code_parent)
        for (let index = 0; index < allCats.length; index++) {
            if (!allCats[index].category_code_parent) {
                allCats[index].catlevel = 0
                let rootArr = []
                rootArr.push(allCats[index])
                catRoots.push(rootArr)
            }
        }
        var findChildren = (parent, catlevel) => {
            // let parentCopy = parent
            let childsOfRoot = childCats.filter((x) => x.category_code_parent == parent[catlevel].category_code)
            catlevel++
            if (childsOfRoot[0]) {
                childsOfRoot[0].catlevel = catlevel
                parent.push(childsOfRoot[0])
                findChildren(parent, catlevel)
                // if (childsOfRoot.length > 1) {
                //     for (let index = 1; index < childsOfRoot.length; index++) {
                //         let rootDouble = parentCopy
                //         rootDouble.push(childsOfRoot[index])
                //         catRoots.push(rootDouble)
                //         findChildren(rootDouble, catlevel)
                //     }
                // }
            } // else{
            //     parent[catlevel].lastlvl = true
            // }
        }
        for (let index = 0; index < catRoots.length; index++) {
            findChildren(catRoots[index], 0)
        }
        catRoots.sort((a, b) => b.length - a.length)
        return catRoots
    },
    /**
     *
     * @param {*} response_data
     * @param {*} allCategories
     * @returns
     */
    extractAdditionalData(response_data, allCategories) {
        return new Promise((resolve, reject) => {
            try {
                let formattedData = response_data.entries.map((entry) => {
                    let obj = {}
                    obj.keyword = entry.data[0].value.value
                    obj.serp_items = this.getSERPItemsObject(entry.data[4] ? (entry.data[4].value ? entry.data[4].value.serp_item_types : null) : null)
                    obj.keyword_difficulty = this.getDifficultyObject(entry.data[5] ? (entry.data[5].value ? entry.data[5].value.keyword_difficulty : null) : null)
                    obj.categories = this.translateCats(entry.data[1].value.categories, allCategories)
                    return obj
                })
                resolve(formattedData)
            } catch (error) {
                reject(error)
            }
        })
    },

    getSERPItemsObject(serp_items) {
        if (!serp_items) return null
        let formatted_serp_items = serp_items.map((item) => {
            let serp_item_obj = all_serp_items.find((entry) => entry.value == item)
            return serp_item_obj
        })
        return formatted_serp_items.filter((item) => item).sort((a, b) => a.id - b.id)
    },
    /**
     * returns chip color for keyword difficulty and sea competition
     * @param {*} difficulty
     * @returns
     */
    getDifficultyObject(difficulty) {
        if (!difficulty) return null
        let ranges = [
            {
                text: "very easy",
                maximum: 15,
                color: "#3ed633"
            },
            {
                text: "easy",
                maximum: 30,
                color: "#8fd633"
            },
            {
                text: "possible",
                maximum: 50,
                color: "#d6ce33"
            },
            {
                text: "difficult",
                maximum: 70,
                color: "#ed6d37"
            },
            {
                text: "hard",
                maximum: 85,
                color: "#ed3737"
            },
            {
                text: "very hard",
                maximum: 100,
                color: "#b30202"
            }
        ]
        for (let index = 0; index < ranges.length; index++) {
            if (difficulty < ranges[index].maximum) {
                let resp = { ...ranges[index], difficulty: difficulty }
                return resp
            }
        }
    },
    /**
     *
     * @param {*} keywordCategories
     * @param {*} allCategories
     * @returns
     */
    translateCats(keywordCategories, allCategories) {
        try {
            if (!keywordCategories) return null
            let translatedCats = []
            for (let index = 0; index < keywordCategories.length; index++) {
                let categoryEntry = allCategories.find((categoryEntry) => categoryEntry.category_code == keywordCategories[index])
                if (categoryEntry) {
                    translatedCats.push(categoryEntry)
                }
            }
            if (translatedCats.length > 0) {
                return translatedCats
            } else {
                return null
            }
        } catch (error) {
            console.log(error)
            return null
        }
    }
}
function splitArray(myArray, chunk_size) {
    let myChunk = []
    let tempArray = []
    for (let index = 0; index < myArray.length; index += chunk_size) {
        myChunk = myArray.slice(index, index + chunk_size)
        tempArray.push(myChunk)
    }
    return tempArray
}
