<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 68 44.96">
        <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
                <path d="M45.42,45H37.37A6.43,6.43,0,0,1,31,38.54V6.42A6.43,6.43,0,0,1,37.37,0h8.05a2,2,0,0,1,0,4H37.37A2.42,2.42,0,0,0,35,6.42V38.54A2.42,2.42,0,0,0,37.37,41h8.05a2,2,0,0,1,0,4Z" />
                <path d="M15.3,45H2.09a2,2,0,1,1,0-4H15.3a2.42,2.42,0,0,0,2.42-2.42V6.42A2.42,2.42,0,0,0,15.3,4H2A2,2,0,0,1,2,0H15.3a6.43,6.43,0,0,1,6.42,6.42V38.54A6.43,6.43,0,0,1,15.3,45Z" />
                <path d="M55.12,35.42A2,2,0,0,1,53.7,32l9.46-9.6L53.48,13a2,2,0,0,1,2.79-2.86L67.4,21a2,2,0,0,1,0,2.84l-10.88,11A2,2,0,0,1,55.12,35.42Z" />
            </g>
        </g>
    </svg>
</template>

<script>
    export default {}
</script>

<style></style>
