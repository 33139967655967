<template>
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 400">
        <title>_</title>
        <path
            d="M203.33,333H101.5a34.33,34.33,0,0,1-34.29-34.29V101.29A34.33,34.33,0,0,1,101.5,67H203.33a36.42,36.42,0,0,1,36.38,36.37V152.5a6,6,0,0,1-12,0V103.37A24.4,24.4,0,0,0,203.33,79H101.5a22.31,22.31,0,0,0-22.29,22.29V298.71A22.31,22.31,0,0,0,101.5,321H203.33a24.4,24.4,0,0,0,24.38-24.37V247.5a6,6,0,0,1,12,0v49.13A36.42,36.42,0,0,1,203.33,333Z" />
        <path
            d="M270.12,264.67a6,6,0,0,1-6-6V229.92H172.67a19.31,19.31,0,0,1-19.29-19.29V189.38a19.31,19.31,0,0,1,19.29-19.29h91.46V141.33a6,6,0,0,1,10.32-4.17l56.67,58.67a6,6,0,0,1,0,8.34l-56.67,58.67A6,6,0,0,1,270.12,264.67Zm-97.46-82.58a7.3,7.3,0,0,0-7.29,7.29v21.25a7.3,7.3,0,0,0,7.29,7.29h97.46a6,6,0,0,1,6,6v19.9L318.45,200l-42.33-43.82v19.9a6,6,0,0,1-6,6Z" />
    </svg>
</template>

<script>
    export default {}
</script>

<style></style>
