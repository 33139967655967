<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 103 86.12">
        <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
                <path
                    class="cls-1"
                    d="M86.17.05a7.86,7.86,0,0,0-6.5,5A55.53,55.53,0,0,1,55.48,33.61c-23.79,13.9-30.93,34.86-31.87,38-7,.46-13.62-.75-18.69-4.8a3,3,0,1,0-3.78,4.74C6.72,76,13.56,77.69,20.82,77.69c24.64,0,54.11-19.92,55.71-21a3,3,0,0,0-3.42-5c-.32.22-22.19,15-42.72,19A67,67,0,0,1,58.54,38.85,61.61,61.61,0,0,0,85.34,7.23a1.79,1.79,0,0,1,1.5-1.16,1.94,1.94,0,0,1,1.84.86c6.85,10.32,13,27.37,3.32,50C82.72,78.71,63.55,81.32,49.08,79.68a3,3,0,1,0-.68,6,64.86,64.86,0,0,0,7.32.42c13.75,0,32.38-4.58,41.86-26.79C108.86,32.87,100,13,93.73,3.58A8,8,0,0,0,86.17.05Z" />
            </g>
        </g>
    </svg>
</template>

<script>
    export default {}
</script>

<style></style>
