// answer_box, app, carousel, multi_carousel, featured_snippet, google_flights, google_reviews,
// google_posts,organic, jobs, knowledge_graph, local_pack, hotels_pack, map, paid, images,
// people_also_ask, related_searches, people_also_search,video, top_stories, twitter, shopping,
// events, mention_carousel, recipes, top_sights, scholarly_articles, popular_products, podcasts,
// questions_and_answers, find_results_on, stocks_box, visual_stories, commercial_units, local_services
export let all_serp_items = [
    {
        id: 1,
        value: "organic",
        tooltip: "Organic: Organic search results"
    },
    {
        id: 2,
        value: "paid",
        tooltip: "Paid: Paid search results"
    },
    {
        id: 3,
        value: "images",
        tooltip: "Images: Image results are displayed whenever you make a search query that Google determines is related to visual content"
    },
    {
        id: 4,
        value: "video",
        tooltip: "Video: Video results are displayed whenever you make a search query that Google determines is related to visual content"
    },
    {
        id: 5,
        value: "shopping",
        tooltip: "Shopping: Google Shopping is the ads service backed by AdWords and Google Merchant Center"
    },
    {
        id: 6,
        value: "answer_box",
        tooltip: "Answer Box: Intends to provide a direct response to a user request"
    },
    {
        id: 7,
        value: "carousel",
        tooltip: "Carousel: Appears usually on the top of the results page and presents information relevant to a user query in the form of a list of elements"
    },
    {
        id: 8,
        value: "find_results_on",
        tooltip: "Find results on: This feature of Google Search displays a carousel of other websites or online directories that Google deems helpful in answering the search query"
    },
    {
        id: 9,
        value: "local_pack",
        tooltip: "Local Pack: Provides users with the necessary information about local establishments relevant to their request"
    },
    {
        id: 10,
        value: "people_also_asked",
        tooltip: "People Also Ask: Provides information in the form of fold-out boxes with questions about the search query"
    },
    {
        id: 11,
        value: "related_searches",
        tooltip: "Related Searches: Consists of the list of questions associated with the original request"
    },
    {
        id: 12,
        value: "rich_snippet",
        tooltip: "Rich Snippet: Intended to provide a direct answer to users questions by displaying a piece of information related to a search query"
    },
    {
        id: 13,
        value: "multi_carousel",
        tooltip: "Multi carousel: Shows a series of illustrated search suggestions based on the original search query"
    },
    {
        id: 14,
        value: "featured_snippet",
        tooltip:
            "Featured Snippet: Intended to provide a direct answer to users questions by displaying a piece of information related to a search query. Featured snippets can appear at the very top of the search results page"
    },
    {
        id: 15,
        value: "google_flights",
        tooltip: "Google Flights: Represents information about flights between two destinations for the selected dates"
    },
    {
        id: 16,
        value: "google_reviews",
        tooltip: "Google Reviews: Displays the presence of user-generated reviews about local businesses"
    },
    {
        id: 17,
        value: "google_posts",
        tooltip: "Google Posts: Shows if a local establishment has shared Google Posts"
    },
    {
        id: 18,
        value: "jobs",
        tooltip: "Jobs: Contains job postings from various job platforms"
    },
    {
        id: 19,
        value: "knowledge_graph",
        tooltip: "Knowledge Graph: displays additional information about the subject of the search query"
    },
    {
        id: 20,
        value: "hotels_pack",
        tooltip: "Google Hotels: Represents a specific type of knowledge graph that shows information about a specific hotel"
    },
    {
        id: 21,
        value: "map",
        tooltip: "Map: Displays the location of the given place on the map and generates route options between different places"
    },
    {
        id: 22,
        value: "people_also_search",
        tooltip: "People also search: Provides short answers to questions related to the original request"
    },
    {
        id: 23,
        value: "top_stories",
        tooltip: "Top Stories: Is triggered by queries that contain a news-intensive topic"
    },
    {
        id: 24,
        value: "twitter",
        tooltip:
            "Twitter: Indicates whether the topic of the search query is linked to the corresponding Twitter account and/or whether the Twitter posts can provide additional information about the topic"
    },
    {
        id: 25,
        value: "events",
        tooltip: "Events: Lists valid future events in a given time and place"
    },
    {
        id: 26,
        value: "mention_carousel",
        tooltip: "Mention Carousel: Shows a set of trusted references to the search query"
    },
    {
        id: 27,
        value: "recipes",
        tooltip: "Recipes: Contains recipe cards with pictures, rating, preparation time and ingredients needed"
    },
    {
        id: 28,
        value: "top_sights",
        tooltip: "Top sights: Displays a carousel of places to visit near the named location"
    },
    {
        id: 29,
        value: "scholarly_articles",
        tooltip: "Scholarly Articles: Provides a preview of several results relevant to the search topic found on Google Scholar"
    },
    {
        id: 30,
        value: "popular_products",
        tooltip: "Popular Products: Search queries with transactional or commercial intent"
    },
    {
        id: 31,
        value: "podcasts",
        tooltip: "Podcasts: When searchers include the word 'podcast' in their search query, Google shows 'podcasts' in the results"
    },
    {
        id: 32,
        value: "questions_and_answers",
        tooltip: "Questions & Answers: When searches are looking for answers or solutions"
    },
    {
        id: 33,
        value: "stocks_box",
        tooltip: "Stocks Box: Displays a market overview of Google Finance for a specific company"
    },
    {
        id: 34,
        value: "visual_stories",
        tooltip: "Visual Stories: Visual stories feature is a unique type of AMP content displayed only on mobile devices"
    },
    {
        id: 35,
        value: "commercial_units",
        tooltip: "Commercial Units: Is a unique type of advertising content for travel and tourist attractions"
    },
    {
        id: 36,
        value: "local_services",
        tooltip: "Local services: Displays search queries related to companies that provide services in a specific area"
    }
]
