<template>
<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 400"><title>_</title><path d="M292.66,298.62H107.34a6,6,0,0,1-6-6V165.71a91.6,91.6,0,0,1,91.5-91.5h14.32a91.6,91.6,0,0,1,91.5,91.5V292.62A6,6,0,0,1,292.66,298.62Zm-179.32-12H286.66V165.71a79.59,79.59,0,0,0-79.5-79.5H192.84a79.59,79.59,0,0,0-79.5,79.5Z"/><path d="M310,322.47H90a6,6,0,0,1-6-6v-9a20.86,20.86,0,0,1,20.83-20.83H295.17A20.86,20.86,0,0,1,316,307.46v9A6,6,0,0,1,310,322.47Zm-214-12H304v-3a8.84,8.84,0,0,0-8.83-8.83H104.83A8.84,8.84,0,0,0,96,307.46Z"/><path d="M200,86.21a6,6,0,0,1-6-6V58.53a6,6,0,1,1,12,0V80.21A6,6,0,0,1,200,86.21Z"/><path d="M208.5,347.47h-17a31,31,0,0,1-31-31,6,6,0,0,1,6-6h67a6,6,0,0,1,6,6A31,31,0,0,1,208.5,347.47Zm-35-25a19,19,0,0,0,18,13h17a19,19,0,0,0,18-13Z"/></svg></template>
</template>
    
<script>
export default {

}
</script>

<style>

</style>