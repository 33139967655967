<template>
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 400">
        <title>_</title>
        <path d="M192.64,312.43A119.82,119.82,0,1,1,277.38,107.9h0a119.79,119.79,0,0,1-84.74,204.53Zm0-227.58a107.79,107.79,0,1,0,76.26,31.54A107.51,107.51,0,0,0,192.64,84.85Z" />
        <path d="M325,331a6,6,0,0,1-4.24-1.76L268.9,277.38a6,6,0,0,1,8.49-8.49l51.88,51.88A6,6,0,0,1,325,331Z" />
    </svg>
</template>

<script>
    export default {}
</script>

<style></style>
