import store from "../../store/index"
import axios from "axios"
export default {
    fetchHistoricalSV(keywords, language_code, location_code, company_id, user_id) {
        return new Promise(async (resolve, reject) => {
            try {
                let req_body = {
                    keywords: keywords.map((keywords) => keywords.keyword),
                    company: company_id,
                    user: user_id,
                    language: language_code,
                    location: location_code
                }

                let authsplits = process.env.VUE_APP_AUTH.split(":")
                const response = await axios({
                    method: "post",
                    url: process.env.VUE_APP_APIURL + "/v2/sources/dataforseo/historicalsv",
                    data: req_body,
                    // auth: "Basic " + window.btoa(process.env.VUE_APP_AUTH)
                    auth: {
                        username: authsplits[0],
                        password: authsplits[1]
                    }
                })
                if (response.data.error) reject(response.data.error)
                resolve(response.data)
            } catch (error) {
                console.log(error)
                reject(error)
            }
        })
    },
    fetchDFS(options, company_id, user_id, endpoint) {
        return new Promise(async (resolve, reject) => {
            try {
                let req_body = {
                    options: options,
                    company: company_id,
                    user: user_id,
                    request_type: endpoint
                }

                let authsplits = process.env.VUE_APP_AUTH.split(":")
                const response = await axios({
                    method: "post",
                    url: process.env.VUE_APP_APIURL + "/v2/sources/",
                    data: req_body,
                    // auth: "Basic " + window.btoa(process.env.VUE_APP_AUTH)
                    auth: {
                        username: authsplits[0],
                        password: authsplits[1]
                    }
                })
                resolve(response.data)
            } catch (error) {
                console.log(error)
                reject(error)
            }
        })
    }
}
